/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { CardTitle, Badge, Card, Row, Col, CardBody, Button, CardHeader,Label,Input, Container,FormGroup, Collapse} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import SimpleHeaderMobile from "components/Headers/SimpleHeaderMobile"
import "../Style.css"

const IndexKehadiranCok = () => {
  const warehouse = localStorage.warehouse;
  const token = localStorage.token;
  const username = localStorage.username;
  const [allAsset, setAllAsset] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
//   const [tanggal, setTanggal] = useState(new Date().toISOString().split("T")[0]);
  const [tanggal, setTanggal] = useState("");

  const [collapse, setCollapse] = useState(false);
  const [status, setStatus] = useState('Closed');

  const onEntering = () => setStatus('Opening...');
  const onEntered = () => setStatus('Opened');
  const onExiting = () => setStatus('Closing...');
  const onExited = () => setStatus('Closed');
  const toggle = () => setCollapse(!collapse);

  const updateDataTable = (page, tanggal) => {
    getAsset(page, tanggal);
    setPage(page);
    setTanggal(tanggal);
  }

  useEffect(() => {
    getAsset(page, perPage, "", "", "", "", "");
  }, []);

  const getAsset = (page,search_date = null ) => {
    let filter = { 
      page: page, 
      per_page: 50,
      search_date: tanggal,
      warehouse_id : parseInt(warehouse),
      username : username
    };

    const data = filter;
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/absensi-karyawan/page`, data, { headers
    })
    .then(data => {
        setAllAsset(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
    })
      .catch(function (error) {
        setAllAsset(error.response.data.response)
      })
  }


  const reset = () => {
    setTanggal("");
    updateDataTable(1, perPage, "", "", "", "");
  }

  return (
    <div style={{overflow:"hidden"}}>
      <SimpleHeaderMobile to="/mobile/staff-absen" judul="Riwayat Kehadiran" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <Card>
                  <CardHeader>
                  <div style={{ justifyContent: "space-between" }}
                    >
                      <h3>Riwayat Kehadiran</h3>
                      <Button color="link" onClick={toggle} style={{ marginBottom: '1rem' }}>
                        {collapse === false ? <i className="fa fa-plus" aria-hidden="true"></i> : <i className="fa fa-minus" aria-hidden="true"></i>}
                        
                      </Button>
                      <Collapse
                        isOpen={collapse}
                        onEntering={onEntering}
                        onEntered={onEntered}
                        onExiting={onExiting}
                        onExited={onExited}
                      >
                          <div style={{ textAlign: "left" }}>
                              <Row className="row-cols-lg-auto g-3 align-items-center">
                                <Col>                        
                                    <FormGroup>
                                    <Label
                                            className="form-control-label"
                                            htmlFor="exampleFormControlSelect3"
                                        >
                                      {/* Tanggal */}
                                    </Label>
                                    <Input
                                      autoComplete="off"
                                      type="date"
                                      value={tanggal}
                                      onChange={e => setTanggal(e.target.value)}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                        <Label>&nbsp;</Label>
                                        <Button type='button' onClick={e => getAsset(allAsset)} className="btn btn-info"><i className="fa fa-filter"></i></Button>
                                </Col>
                              </Row>
                          </div>
                      </Collapse>
                      
                    </div>

                  </CardHeader>
                  {allAsset === null ? (
                     <CardHeader>
                          <center><h3>DATA NOT FOUND</h3></center>
                      </CardHeader>
                    ):(
                    <div style={{ height: '  73vh', overflowY: "auto" ,cursor: "pointer" }}>
                            {allAsset.map((v) => (
                            <Card style={{ marginBottom: "0rem"}} 
                            >
                              <CardBody>
                                <Row>
                                  <div className="col">
                                    <CardTitle className="h5 text-uppercase text-muted mb-0 text-black">
                                    {v.tgl}&nbsp;{v.check}&nbsp;{v.status_kerja === 1 ? (
                                      <Badge color="success">{v.keterangan}</Badge>
                                  ) : v.status_kerja === 2 ? (
                                      <Badge color="danger">{v.keterangan}</Badge>
                                  ): <Badge color="warning">{v.keterangan}</Badge>}
                                    </CardTitle>
                                    <span className="h5 font-weight-bold mb-0 text-black">
                                    {v.name}
                                    </span>
                                  </div>
                                  <Col className="col-auto">
                                  <a
                                    className="avatar rounded-circle mr-3"
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                  >
                                    {v.karyawan_image === "" ?(
                                          <img
                                            alt=""
                                            src={"https://i.imgur.com/Hzxpp28.png"}
                                          /> 
                                        ):(
                                            <img
                                                alt=""
                                                src={v.karyawan_image}
                                              />
                                        )}
                                  </a>
                                      
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                            ))
                        } 
                    </div>
                    )}
                </Card>
          </div>
        </Row>
      </Container>
      <div className="d-lg-none d-xl-none">
       <Link 
        style={{
              paddingTop: 20,
              height: 70,
              width: 70, 
              borderRadius: "50%",
            }}  
            className="btn-danger text-center fixed-whatsapp encepnurdin" 
            to="/mobile/dispensasi-karyawan/izin/create"
            >
                <i className="fa fa-plus fa-2x" aria-hidden="true"/>
        </Link> 
        </div>
    </div>
  );
}

export default IndexKehadiranCok;