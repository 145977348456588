/*eslint-disable*/
import React, { useEffect, useState , useRef} from 'react';
import { 
    Card, 
    Row, 
    Col, 
    CardBody,
    Button ,
    CardTitle,
    Input,
    Badge,
    CardHeader,
    InputGroup,
    Container, 
    Modal, 
    ModalHeader, 
    ModalBody,
    Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';
import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import "./Style.css";
import MobileHeader from 'components/Headers/MobileHeader';

const AbsensiStaff = () => {
  const token = localStorage.token;
  const userid = localStorage.uic;
  const username = localStorage.username;
  const [long, setLong] = useState(null);
  const [lat, setLat] = useState(null);
  const [longmasuk, setLongMasuk] = useState(null);
  const [latmasuk, setLatMasuk] = useState(null);
  const [longkeluar, setLongKeluar] = useState(null);
  const [latkeluar, setLatKeluar] = useState(null);
  const [buttoncekin, setButtonCekIn] = useState("");
  const [buttoncekout, setButtonCekOut] = useState("");
  const [clockin,setClockIn] = useState("");
  const [clockout,setClockOut] = useState("");
  const [iconout, setIconOut] = useState("");
  const [modal6, setModal6] = useState(false);
	const toggless = () => setModal6(!modal6);
  const [name,setName] = useState("");
  const [picture,setPicture] = useState("");
  const [jabatan,setJabatan] = useState("");
  const [salary, setSalary] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const items = [
    {
      src: 'https://i.imgur.com/HoeWKZ9.jpeg',
      altText: 'Absen',
      title: 'Absensi'
    },
    {
      src: 'https://i.imgur.com/ugaWS91.jpeg',
      altText: 'Cuti',
      title: 'Izin Cuti'
    },
    {
      src: 'https://i.imgur.com/MF3Ulir.jpeg',
      altText: 'Dinas',
      title: 'Izin Dinas'
    }
  ];

    // Fungsi next untuk beralih ke slide berikutnya
    const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    };
  
    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    };
  
    const goToIndex = (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
    };
  
    // Autoplay: Mengubah slide secara otomatis setiap 3 detik
    useEffect(() => {
      const interval = setInterval(() => {
        next();
      }, 3000); // Mengganti slide setiap 3 detik
  
      return () => {
        clearInterval(interval); // Membersihkan interval saat komponen unmount
      };
    }, [activeIndex, animating]);
  
    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={item.src}
        >
          <Row xs="1">
            <Col>
              <Card className="card-stats mb-0 mb-xl-2">
                <CardBody>
                  <Row>
                    <div className="col ml--2">
                      <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                        <b>{item.title}</b>
                      </CardTitle>
                      <img src={item.src} alt={item.altText} className="responsive-img" />
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CarouselItem>
      );
    });

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }  

  const EditData = async () => {setTimeout(handleClick, 1000);};
	const handleClick = () => {CekLongitude.current.onClick();}
	const CekLongitude = useRef();

  const ButtonMasukCok = async () => {window.location.href = "/mobile/staff-absen/masuk"};
  const ButtonKeluarCok = async () => {window.location.href = "/mobile/staff-absen/pulang"};
  const ButtonCuti = async () => {window.location.href = "/mobile/dispensasi-karyawan/izin"};
  const ButtonDinas = async () => {window.location.href = "/mobile/dinas/page"};
  const ButtonKehadiran = async () => {window.location.href = "/mobile/kehadiran/page"};

  const togglePasswordVisiblity = () => {
		setPasswordShown(passwordShown ? false : true);
	};

  const successCallback = (position) => {
  };
  
  const errorCallback = (error) => {
  };
  
  navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

  useEffect(() => {
    setInterval(() => {
      ceklangtut()
    }, 1500);
    getByUser()
  }, [])

  
  const ceklangtut=() => {
    navigator.geolocation.getCurrentPosition(function(position) {
      successCallback();
      errorCallback()
      setLat(position.coords.latitude);
      setLong(position.coords.longitude);
      EditData();
    });
  }

  const getJamdanButton = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    let data = {
			username: username,
      latitude : lat, 
      longitude : long,
		};
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/absensi-karyawan/get-by-username`,data,
        { headers }
      )
      .then((data) => {
        setButtonCekIn(data.data.response.check_in)
        setButtonCekOut(data.data.response.check_out)
        setClockIn(data.data.response.clock_in)
        setClockOut(data.data.response.clock_out)
        setLongMasuk(data.data.response.longitude_dtg)
        setLongKeluar(data.data.response.longitude_plg)
        setLatMasuk(data.data.response.latitude_dtg)
        setLatKeluar(data.data.response.latitude_plg)
        setIconOut(data.data.response.icon_out)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getByUser = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/users/profil/${userid}`,
        { headers }
      )
      .then((data) => {
        setName(data.data.response.name);
        setPicture(data.data.response.karyawan_image);
        setJabatan(data.data.response.jabatan);
        setSalary(data.data.response.gaji);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const googleMapsApiKey = `${process.env.REACT_APP_MAP}`
  
  const PointMasuk = () => 
    <div style={{
      padding: '5px 5px',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)'
    }}>
      <img src="https://i.ibb.co/XYf5GGc/masuk.png" width="40px" alt="point masuk" />
    </div>;

const PointKeluar = () => 
<div style={{
  padding: '5px 5px',
  display: 'center',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  transform: 'translate(-50%, -50%)'
}}>
    <img src="https://i.ibb.co/hCtKVzc/pulang.png" width="40px" alt="poin keluar"/>
</div>;

const defaultProps = {
  center: {
    lat: lat !== null ? lat : -7.3106144,
    lng: long !== null ? long : 112.6841525,
  },
  default:{
    lat: -7.3106144,
    lng: 112.6841525
  },
  zoom: 14
};

  return (
    <div>
      {/* <div className="dvLoading" ></div> */}
    <Button hidden color="danger" outline onClick={handleClick}> Fungsi UseReff Cek Lat long </Button>
    <Button hidden color="danger" outline ref={CekLongitude} onClick={()=>{getJamdanButton()}}>fungsi getbutton</Button>
    <MobileHeader name={name} picture={picture} jabatan={jabatan}/>
      <Container className="mt--5" fluid>
        <Row>
          <div className="col">
                  <Row xs="2"> 
                    <Col>
                      <Card className="card-stats mb-0 mb-xl-1">
                        <CardBody>
                          <Row>
                            <div className='col ml--1'>
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                <b>Masuk</b>
                              </CardTitle>
                              <span className="h3 font-weight-bold mb-0">
                              <Badge color="info">
                                {clockin}
                              </Badge>
                              </span>
                            </div>
                              <div className='float-right'>
                                <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                  <i className="fas fa-sign-in-alt" />
                                </div>
                              </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="card-stats mb-0 mb-xl-2">
                        <CardBody>
                          <Row>
                            <div className='col ml--1'>
                            <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  <b>Pulang</b>
                                </CardTitle>
                                <span className="h3 font-weight-bold mb-0">
                                <Badge color="danger">
                                  {clockout}
                                </Badge>
                                </span>
                            </div>
                              <div className='float-right'>
                                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                  <i className="fas fa-sign-out-alt" />
                                </div>
                              </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
            {/* </Card> */}
            <CardBody></CardBody>
            <div className="d-lg-block d-none">
              <Card >
                <CardHeader>
                <h3>Maps</h3>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ textAlign: "center" }}>
                    </div>
                  </div>
                </CardHeader>
                <div style={{ height: '30vh', width: '100%' }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: googleMapsApiKey }}
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                    >
                      {iconout < 2 ?(
                                  ""
                                ):(
                          <PointKeluar
                            lng={longkeluar}
                            lat={latkeluar}
                            center={{ lat: latkeluar, lng: longkeluar }}
                          />
                      )}
                      <PointMasuk
                        lng={longmasuk}
                        lat={latmasuk}
                        center={{ lat: latmasuk, lng: longmasuk }}
                      />
                    </GoogleMapReact>
                  </div>
              </Card>
            </div>
              <Row xs="4">
                <div onClick={ButtonCuti} style={{cursor: 'pointer'}}>
                  <Col>
                    <div className='col-xs-5' style={{textAlign: 'center', backgroundColor:"#63cbff", borderRadius:"20px"}}>
                        <Card style={{borderRadius:"20px", backgroundColor:"#e6f7ff"}}>
                          <img
                              alt="..."
                              src={require("assets/img/theme/cuti.png").default}
                              width="100%"
                          />
                        </Card>
                    </div>
                  </Col>
                </div>
                <div onClick={ButtonDinas} style={{cursor: 'pointer'}}>
                  <Col>
                  <div className='col-xs-5' style={{textAlign: 'center', backgroundColor:"#63cbff", borderRadius:"20px"}}>
                      <Card style={{borderRadius:"20px", backgroundColor:"#e6f7ff"}}>
                        <img
                            alt="..."
                            src={require("assets/img/theme/dinas.png").default}
                            width="100%"
                        />
                      </Card>
                    </div>
                    <br></br>
                  </Col>
                </div>
                <div onClick={ButtonKehadiran} style={{cursor: 'pointer'}}>
                {/* <Link onClick={ButtonKehadiran}> */}
                  <Col>
                  <div className='col-xs-5' style={{textAlign: 'center', backgroundColor:"#63cbff", borderRadius:"20px"}}>
                        <Card style={{borderRadius:"20px", backgroundColor:"#e6f7ff"}}>
                        <img
                            alt="..."
                            src={require("assets/img/theme/kehadiran.png").default}
                            width="100%"
                        />
                        </Card>
                    </div>
                    <br></br>
                  </Col>
                {/* </Link>  */}
                </div>
                <div onClick={toggless} style={{cursor: 'pointer'}}>
                  <Col>
                  <div className='col-xs-5' style={{textAlign: 'center', backgroundColor:"#63cbff", borderRadius:"20px"}}>
                      <Card style={{borderRadius:"20px", backgroundColor:"#e6f7ff"}}>
                        <img
                            alt="..."
                            src={require("assets/img/theme/gaji.png").default}
                            width="100%"
                        />
                      </Card>
                    </div>
                    <br></br>
                  </Col>
                </div>
              </Row>
              {/* <Row xs="1"> 
                    <Col>
                      <Card className="card-stats mb-0 mb-xl-2">
                        <CardBody>
                          <Row>
                            <div className='col ml--2'>
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                <b>Pengumuman</b>
                              </CardTitle>
                              <img src="https://i.imgur.com/HoeWKZ9.jpeg" alt="Example" className="responsive-img" />
                                
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
              </Row> */}
              <div>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
    </div>
                <br></br>
                <Row xs="1"> 
                    <Col>
                      <Card className="card-stats mb-0 mb-xl-2">
                        <CardBody>
                          <Row>
                            <div className='col ml--2'>
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                <b>Informasi Gaji</b> &nbsp;
                                {passwordShown !== true ? (
                                    <i className="fa fa-eye" aria-hidden="true" onClick={togglePasswordVisiblity} />
                                  ) : (
                                    <i className="fa fa-eye-slash" aria-hidden="true" onClick={togglePasswordVisiblity} />
                                  )}
                              </CardTitle>
                              <InputGroup>
                                  <Input
                                    bsSize="sm"
                                    style={{ fontSize: "17px",  fontFamily:'Tahoma', fontWeight: 'bold'}}
                                    plaintext
                                    type={passwordShown ? "text" : "password"}
                                    value={formatRupiah(salary)}
                                    onChange={(e) => {
                                      setSalary(e.target.value);
                                  }}
                                  />
                                </InputGroup>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                              <i className="fas fa-wallet"></i>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                </Row>
                <br></br>
                <Row xs="1"> 
                    <Col>
                      <Card className="card-stats mb-0 mb-xl-2">
                      </Card>
                    </Col>
                </Row>
                <br></br>
                <Row xs="1"> 
                    <Col>
                      <Card className="card-stats mb-0 mb-xl-2">
                      </Card>
                    </Col>
                </Row>
                <br></br>
                <Row xs="1"> 
                    <Col>
                      <Card className="card-stats mb-0 mb-xl-2">
                      </Card>
                    </Col>
                </Row>
                
          </div>
        </Row>
      </Container>
        <Modal isOpen={modal6} toggle={toggless}>
          <ModalHeader toggle={toggless} align="center"></ModalHeader>
          <ModalBody align="center">
          <font size="5"><b>Coming Soon</b></font><br/><br/>
          <i className="fa fa-cog fa-5x fa-spin" aria-hidden="true"></i><br/><br/>
          <Button color="secondary" onClick={toggless}>
            Cancel
          </Button>
          </ModalBody>
        </Modal>
        <nav className="navbar bg-white navbar-expand fixed-bottom d-md-none d-lg-none d-xl-none">
            <ul className="navbar-nav nav-justified w-100">
                <li className="nav-item">
                    <a href="/mobile/staff-absen" className="nav-link text-center">
                        <svg width="1.5em" height="1.5em" viewBox="0 0 16 20" className="bi bi-house" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                            <path fillRule="evenodd"
                                d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z" />
                        </svg>
                        <span className="small d-block">Home</span>
                    </a>
                </li>
                <li className="nav-item">
                              <div className='btn-links' style={{display: "flex", justifyContent: "center", alignItems: "center" }} >
                              <div 
                               style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems:"center",
                                paddingTop: 12,
                                height: 90,
                                width: 90, 
                                position:`absolute`,
                                backgroundColor:"white",
                                borderRadius: "50%",
                                   }}
                                   >
                                       <i className="fa fa-spinner fa-spin fa-3x fa-fw" style={{color:"#fab005"}}></i>

                              </div> 
                                {buttoncekin === 2 && buttoncekout === 2 && (
                                    <div 
                                    style={{
                                     display: "flex",
                                     justifyContent: "center",
                                     alignItems:"center",
                                     paddingTop: 10,
                                     height: 80,
                                     width: 80, 
                                     position:`absolute`,
                                     borderRadius: "50%",
                                        }}  
                                        className="btn btn-warning" 
                                        
                                        >
                                            <i className="fa fa-times fa-3x"/>
                                    </div>
                                )}
                                {buttoncekin && buttoncekin === 1 && (
                                    <Button style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems:"center",
                                        paddingTop: 10,
                                        height: 80,
                                        width: 80, 
                                        position:`absolute`,
                                        borderRadius: "50%",
                                      }}  color="info" onClick={ButtonMasukCok}>
                                        <i className="fas fa-sign-in-alt fa-3x"/> 
                                    </Button>
                                )}
                                {buttoncekout && buttoncekout === 1 && (
                                    <Button style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems:"center",
                                      paddingTop: 10,
                                      height: 80,
                                      width: 80, 
                                      position:`absolute`,
                                      borderRadius: "50%",
                                    }}  color="danger" onClick={ButtonKeluarCok}>
                                      <i className="fas fa-sign-out-alt fa-3x"/> 
                                    </Button>
                                )}
                                </div>
                </li>
                <li className="nav-item">
                    <a href="/mobile/profile" className="nav-link text-center">
                        <svg width="1.5em" height="1.5em" viewBox="0 0 16 20" className="bi bi-person" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                        </svg>
                        <span className="small d-block">Akun</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
  );
}

export default AbsensiStaff;