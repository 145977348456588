import React, { useEffect, useRef, useState, useCallback } from "react";
import Webcam from "react-webcam";
import * as faceapi from "face-api.js";
import { Alert } from 'reactstrap';

const FaceDetectionCamera = ({ setImageFile }) => {
  const webcamRef = useRef(null);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isCapturing, setIsCapturing] = useState(false);
  const onDismiss = () => setModalIsOpen(false);

  const loadModels = async () => {
    const MODEL_URL = process.env.PUBLIC_URL + "/models";
    await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
    await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
    await faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL);
    setModelsLoaded(true);
  };

  useEffect(() => {
    loadModels();
  }, []);

  // const capture = async () => {
  //   if (!isCapturing) {
  //     setIsCapturing(true);

  //     const imageSrc = webcamRef.current.getScreenshot();
  //     const img = new Image();
  //     img.src = imageSrc;

  //     img.onload = async () => {
  //       const detections = await faceapi
  //         .detectAllFaces(img, new faceapi.TinyFaceDetectorOptions())
  //         .withFaceLandmarks()
  //         .withFaceExpressions();

  //       if (detections.length > 0) {
  //         setTimeout(() => {
  //           setImageFile(imageSrc);
  //           setModalIsOpen(false);
  //           setIsCapturing(false);
  //         }, 1000);
  //       } else {
  //         setModalIsOpen(true);
  //         setIsCapturing(false);
  //       }
  //     };
  //   }
  // };

  const capture = useCallback(async () => {
    if (!isCapturing) {
      setIsCapturing(true);

      const imageSrc = webcamRef.current.getScreenshot();
      const img = new Image();
      img.src = imageSrc;

      img.onload = async () => {
        const detections = await faceapi
          .detectAllFaces(img, new faceapi.TinyFaceDetectorOptions())
          .withFaceLandmarks()
          .withFaceExpressions();

        if (detections.length > 0) {
          setTimeout(() => {
            setImageFile(imageSrc);
            setModalIsOpen(false);
            setIsCapturing(false);
          }, 1000);
        } else {
          setModalIsOpen(true);
          setIsCapturing(false);
        }
      };
    }
  }, [isCapturing, setImageFile]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (modelsLoaded) {
        capture();
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelsLoaded]);

  return (
  <>
  <Alert color="danger" isOpen={modalIsOpen} toggle={onDismiss}>
    Posisikan wajah pada kamera
    </Alert>
    <div style={{ position: "relative", width: "100%" }}>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        style={{
          width: "100%",
          transform: "scaleX(-1)",
          WebkitTransform: "scaleX(-1)",
        }}
      />
      
      {/* {modalIsOpen && (
        <div
          style={{
            position: "absolute",
            bottom: "10px", // Berada sedikit di atas bawah layar
            left: "50%",
            transform: "translateX(-50%)",
            padding: "10px 20px",
            backgroundColor: "rgba(255, 0, 0, 0.8)", // Warna merah dengan sedikit transparansi
            color: "#fff",
            fontSize: "18px",
            borderRadius: "5px",
            textAlign: "center",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            zIndex: 10, // Supaya selalu di atas
          }}
        >
          Posisikan wajah pada kamera
        </div>
      )} */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "99%",
          height: "99%",
          opacity: "0.5",
          transform: "translate(-50%, -50%)",
          boxSizing: "border-box",
          pointerEvents: "none",
          background: "url('https://i.imgur.com/Es4Nl1O.png') no-repeat center center",
          backgroundSize: "cover",
        }}
      ></div>
      
    </div>
    
    </>
  );
};

export default FaceDetectionCamera;
